import './App.css';
import TravelForm from './Forms/TravelForm'; // Corrected import

function App() {
  return (
    <div className="App">
        <TravelForm /> {/* Usage of the TravelForm component */}
    </div>
  );
}

export default App;
