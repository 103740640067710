import React, { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";

function TravelForm() {
  const [formData, setFormData] = useState({
    name: "",
    position: "",
    telephone: "",
    department: "",
    travelClass: "Economy",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);
  const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/submit-form`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        setIsSubmitted(true);
        setIsSuccessPopupOpen(true);
        setPopupMessage("Submission completed successfully");
      } else {
        setIsErrorPopupOpen(true);
        setPopupMessage("Failed to submit form");
      }
    } catch (error) {
      console.error(error);
      setIsErrorPopupOpen(true);
      setPopupMessage("An error occurred while submitting the form");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="page">
      <div className="container">
        {/* React spinner */}
        <ClipLoader color={"#000"} loading={isLoading} size={35} />
        {/* Success Popup */}
        {isSuccessPopupOpen && (
          <div className="popup success">
            <button
              className="close-btn"
              onClick={() => setIsSuccessPopupOpen(false)}
            >
              ×
            </button>
            <div className="popup-content">
              <img
                src="success-icon.svg"
                alt="Success"
                className="popup-icon"
              />
              <p style={{ color: "black" }}>{popupMessage}</p>
            </div>
          </div>
        )}

        {/* Error Popup */}
        {isErrorPopupOpen && (
          <div className="popup error">
            <button
              className="close-btn"
              onClick={() => setIsErrorPopupOpen(false)}
            >
              ×
            </button>
            <div className="popup-content">
              <img src="error-icon.svg" alt="Error" className="popup-icon" />
              <p>{popupMessage}</p>
            </div>
          </div>
        )}

        <div className="logo">
          <img src="logo.png" alt="Logo" />
        </div>
        <div>
          <h3 className="title">TRAVEL REQUISITION FORM</h3>
          <hr
            style={{
              fontSize: "0.1px",
              backgroundColor: "#d6d6d6",
              marginBottom: "10px",
            }}
          />
        </div>
        <div className="form">
          <form onSubmit={handleSubmit}>
            <div>
              <div className="subtitle">PART A: Details of Applicant</div>
              <div className="form-section">
                <div>
                  <label className="label">Name:</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label className="label">Telephone:</label>
                  <input
                    type="text"
                    name="telephone"
                    value={formData.telephone}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label className="label" style={{ width: "30%" }}>
                    ATA Ref: (as per attached approved ATA):
                  </label>
                  <input
                    type="text"
                    name="ATA"
                    value={formData.ATA}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label className="label">Position:</label>
                  <input
                    type="text"
                    name="position"
                    value={formData.position}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label className="label">Department:</label>
                  <input
                    type="text"
                    name="department"
                    value={formData.department}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label className="label">Travel Class:</label>
                  <select
                    name="travelClass"
                    value={formData.travelClass}
                    onChange={handleChange}
                  >
                    <option value="Economy">Economy</option>
                    <option value="Business">Business</option>
                    <option value="First">First</option>
                  </select>
                </div>
              </div>
            </div>
            <br />

            <div className="subtitle">Description and Purpose of Travel</div>
            <div className="form-section">
              <div className="form-group">
                <label htmlFor="travelDescription" className="label">
                  Description:
                </label>
                <textarea
                  id="description"
                  name="travelDescription"
                  placeholder="Type here..."
                  value={formData.travelDescription}
                  onChange={handleChange}
                ></textarea>
              </div>

              <label className="label">Budget Cost Allocation:</label>
              <table className="budget-table">
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Description</th>
                    <th>Code</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Department:</td>
                    <td>
                      <input
                        type="text"
                        name="department"
                        value={formData.department}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="departmentCode"
                        value={formData.departmentCode}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Account:</td>
                    <td>
                      <input
                        type="text"
                        name="account"
                        value={formData.account}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="accountCode"
                        value={formData.accountCode}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Source of Fund:</td>
                    <td>
                      <input
                        type="text"
                        name="sourceOfFund"
                        value={formData.sourceOfFund}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="fundCode"
                        value={formData.fundCode}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Donor Category:</td>
                    <td>
                      <input
                        type="text"
                        name="donorCategory"
                        value={formData.donorCategory}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="donorCode"
                        value={formData.donorCode}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Thematic Area:</td>
                    <td>
                      <input
                        type="text"
                        name="thematicArea"
                        value={formData.thematicArea}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="thematicCode"
                        value={formData.thematicCode}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="form-section">
              <div>
                <label className="label">Requesting Officer:</label>
                <input
                  type="text"
                  name="officer"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="label">Date:</label>
                <input
                  type="date"
                  name="officerDate"
                  value={formData.officerDate}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="label" style={{ width: "30%" }}>
                  Recommended by Supervisor:
                </label>
                <input
                  type="text"
                  name="supervisor"
                  value={formData.supervisor}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="label">Signature:</label>
                <input
                  type="text"
                  name="signature"
                  className="signature"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="label">Date:</label>
                <input
                  type="date"
                  name="supervisorDate"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
            </div>
            <br />

            <div className="form-section">
              <div className="subtitle">PART B: Travel Itinerary</div>
              <table className="itinerary-table">
                <thead>
                  <tr>
                    <th rowspan="2">Departure & Arrival Date (Gaborone)</th>
                    <th rowspan="2">Destination</th>
                    <th colspan="2">Nights</th>
                    <th colspan="3">UN Rate</th>
                    <th colspan="2">TOTAL In US$</th>
                  </tr>
                  <tr>
                    <th>Planned</th>
                    <th>Actual*</th>
                    <th>+40%</th>
                    <th>+30%</th>
                    <th>+20%</th>
                    <th>Planned</th>
                    <th>Actual*</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        type="date"
                        name="departureDate"
                        value={formData.departureDate}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="destination"
                        value={formData.destination}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="nightsPlanned"
                        value={formData.nightsPlanned}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="nightsActual"
                        value={formData.nightsActual}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="unRate40"
                        value={formData.unRate40}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="unRate30"
                        value={formData.unRate30}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="unRate20"
                        value={formData.unRate20}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="totalPlanned"
                        value={formData.totalPlanned}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="totalActual"
                        value={formData.totalActual}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  {/* split */}
                  <tr>
                    <td>
                      <input
                        type="date"
                        name="departureDate2"
                        value={formData.departureDate2}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="destination2"
                        value={formData.destination2}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="nightsPlanned2"
                        value={formData.nightsPlanned2}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="nightsActual2"
                        value={formData.nightsActual2}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="unRate402"
                        value={formData.unRate402}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="unRate302"
                        value={formData.unRate302}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="unRate202"
                        value={formData.unRate202}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="totalPlanned2"
                        value={formData.totalPlanned2}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="totalActual2"
                        value={formData.totalActual2}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  {/* split */}
                  <tr>
                    <td>
                      <input
                        type="date"
                        name="departureDate3"
                        value={formData.departureDate3}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="destination3"
                        value={formData.destination3}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="nightsPlanned3"
                        value={formData.nightsPlanned3}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="nightsActual3"
                        value={formData.nightsActual3}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="unRate403"
                        value={formData.unRate403}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="unRate303"
                        value={formData.unRate303}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="unRate203"
                        value={formData.unRate203}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="totalPlanned3"
                        value={formData.totalPlanned3}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="totalActual3"
                        value={formData.totalActual3}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>
                * To be completed by the Officer within 48 hours of return.
                Ticket stubs should be attached.
              </p>
            </div>
            <br />
            <div className="form-section">
              <div className="subtitle">
                PART C: Certification by Finance Manager
              </div>
              <div>
                <label className="label">Name:</label>
                <input
                  type="text"
                  name="fnSignature"
                  value={formData.fnSignature}
                  onChange={handleChange}
                  placeholder=""
                />
              </div>
              <div>
                <label className="label">Date:</label>
                <input
                  type="date"
                  name="certDate"
                  value={formData.certDate}
                  onChange={handleChange}
                />
              </div>
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
}

export default TravelForm;
